@import 'shared/scss-helpers/fonts.scss';
@import '../../../sharedStyles.scss';

.contentWelcome {
  position: relative;
  color: black;
  max-width: 100%;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e3e3e1;
}

.contentWelcomeContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: calc(var(--vertical-spacing-0-5) * 12.5)
    calc(var(--horizontal-spacing-0-5) * 10.5) 0;

  &.celebration {
    padding-top: calc(var(--vertical-spacing-0-5) * 2.5);
    padding-bottom: var(--vertical-spacing-5);
  }

  &.largeCTA {
    padding-top: calc(var(--vertical-spacing-0-5) * 6.25);
  }
}

.contentWelcomeMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  font-size: 36px;
  line-height: 38px;
  font-family: 'nyt-karnakcondensed';
  font-weight: bold;
  margin-bottom: var(--vertical-spacing-1-5);
  position: relative;
  text-align: center;

  @media (min-width: 760px) {
    font-size: 44px;
    line-height: 1.045em;
  }

  @media (min-width: 992px) {
    font-size: 50px;
    line-height: 52px;
  }

  &.small {
    font-size: 1.125em;
    line-height: 1.111;
  }
}

.subtitle {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: calc(var(--vertical-spacing-0-5) * 7);
  font-family: 'nyt-karnak';
  text-align: center;
  font-weight: 400;
  font-size: 24.8px;

  @media (min-width: 760px) {
    max-width: 300px;
    font-size: 32px;
    line-height: 36px;
  }

  @media (min-width: 992px) {
    max-width: 375px;
    font-size: 38px;
    line-height: 44px;
    margin-bottom: calc(var(--vertical-spacing-0-5) * 9);
  }

  .bold {
    font-family: 'nyt-karnakcondensed';
  }
}

/* ICON */
.icon {
  height: 64px;
  margin-bottom: var(--vertical-spacing-2);
  display: block;
  width: 100%;
  text-align: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .largeCTA & {
    @media (max-width: 768px) {
      height: 50px;
    }
  }

  @media (min-width: 768px) {
    height: 80px;
    margin-bottom: var(--vertical-spacing-1-5);
  }

  &.small {
    height: 40px;
    margin-bottom: var(--vertical-spacing-1);

    @media (min-width: 768px) {
      margin-top: calc(var(--vertical-spacing-0-5) * 2.5);
    }
  }
}

.dateContainer {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.date {
  display: block;
  font-size: 1em;
  line-height: 1.25;
  letter-spacing: 0.005em;
  font-family: 'nyt-franklin';
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.wordleMeta {
  display: block;
  font-size: 1em;
  line-height: 1.25;
  letter-spacing: 0.005em;

  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.buttonContainer {
  @include button-container-styles;
  margin-bottom: var(--vertical-spacing-3);

  @media (min-width: 760px) {
    margin-bottom: calc(var(--vertical-spacing-0-5) * 7);
    flex-direction: row;
  }

  .button {
    @include button-styles;
    background: black;
    color: white;
    margin: 0 calc(var(--horizontal-spacing-0-5) * 2.5)
      var(--vertical-spacing-1);
    font-weight: 400;

    @media (max-width: 760px) {
      display: flex;
      justify-content: center;
      font-weight: $weight-medium-bold;
      align-items: center;
      width: 155px;
    }

    @media (min-width: 760px) {
      width: 180px;
    }

    &.large {
      width: 210px;
      padding: 0 calc(var(--horizontal-spacing-0-5) * 2.5);
    }

    &.secondary {
      background: none;
      color: black;
      border: 1px solid;
      letter-spacing: 0.01em;
    }

    //Games Sale
    &.gameSaleStyle {
      min-width: 11.85em; // fallback if min not supported
      min-width: min(11.85em, 180px);
      font-size: clamp(0.95em, 1vw + 0.125em, 1em);
      font-weight: $weight-medium-bold;
      padding: 0px;
    }
  }

  a {
    all: inherit;
  }
}

.back {
  position: absolute;
  display: flex;
  align-items: center;
  height: var(--header-height);
  top: 0;
  left: var(--header-padding-x);

  button::before {
    background: var(--hybrid-back) center no-repeat !important;
    background-position-x: 0px !important;
  }
}

.ctaSales {
  display: none !important;
}

:global(body:not(.pz-sub)) {
  .gamesSales {
    .ctaBau {
      display: none !important;
    }
    .ctaSales {
      display: unset !important;
    }
  }
}
